import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert'

class Shortener extends React.Component {

    constructor() {
        super();
        this.state = {
            submitted: false,
            utmFilled: true,
            longUrl: '',
            utmSource: '',
            utmMedium: '',
            utmCampaign: '',
            shortUrl: '',
        };

        this.setLongUrl = this.setLongUrl.bind(this);
        this.submit = this.submit.bind(this);
    }

    setLongUrl = (event) => {
        this.setState({
             longUrl: event.target.value, 
             submitted: false,
        });
    }

    setUtmSource = (event) => {
        this.setState({
             utmSource: event.target.value, 
             submitted: false,
        });

        this.setUtmValidity();
    }

    setUtmMedium = (event) => {
        this.setState({
             utmMedium: event.target.value, 
             submitted: false,
        });

        this.setUtmValidity();
    }

    setUtmCampaign = (event) => {
        this.setState({
             utmCampaign: event.target.value, 
             submitted: false,
        });

        this.setUtmValidity();
    }

    setUtmValidity = () => {
        this.setState({ 
            utmFilled: (this.state.utmSource.trim() && this.state.utmMedium.trim() && this.state.utmCampaign.trim())
                || (!this.state.utmSource.trim() && !this.state.utmMedium.trim() && !this.state.utmCampaign.trim()) 
        })
    }

    submit(event) {

        var url = this.state.longUrl;
        if (this.state.utmSource) { // if utm params are filled

            url = url + '?utm-source=' + this.state.utmSource 
                + '&utm-medium=' + this.state.utmMedium
                + '&utm-campaign=' + this.state.utmCampaign;

        }

        fetch(window.location.href.replace(/\/+$/, '') + '/url/short/', {
            method: 'POST',
            body: JSON.stringify({
                longUrl: url
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            if (!response.ok) {
                alert('Something went wrong!')
                return;
            }

            response.json().then(json => {
                const { shortUrl } = json;

                this.setState({ shortUrl: shortUrl })
              });
            this.setState({ submitted: true });
        })

        event.preventDefault();
    }

    render() {
        return (
            <div className='form-container'>

                <div className='form-title'>
                    <img style={{ width: 250 }} src={require('./assets/akvelon-logo.png')} alt='Akvelon'/>
                    <img style={{ width: 200, marginTop: '3%' }} src={require('./assets/shortener-logo.png')} alt='Akvelon'/>
                </div>

                <div>
                    <Form onSubmit={this.submit}>
                        <Form.Group as={Row} className='mb-3' controlId='formLongUrl'>
                            {/* <img style={{ width: 100, height: 50 }} src={require('./assets/url.png')} /> */}
                            <Form.Label column sm={3} className="form-label" >URL</Form.Label>
                            <Col sm={9}>
                                <Form.Control 
                                    type='url' 
                                    placeholder='Enter the long URL' 
                                    onChange={this.setLongUrl}
                                    />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={3} className="form-label">UTM Parameters (optional)</Form.Label>

                            <Col sm={9}>
                                <Form.Control className='form-utm-param-control' type='text' placeholder='Source' onChange={this.setUtmSource} isInvalid={!this.state.utmFilled}/>
                                <Form.Control.Feedback type="invalid">All UTM parameters must be either filled or empty</Form.Control.Feedback>
                                <Form.Control className='form-utm-param-control' type='text' placeholder='Medium' onChange={this.setUtmMedium} isInvalid={!this.state.utmFilled}/>
                                <Form.Control.Feedback type="invalid">All UTM parameters must be either filled or empty</Form.Control.Feedback>
                                <Form.Control className='form-utm-param-control' type='text' placeholder='Campaign' onChange={this.setUtmCampaign} isInvalid={!this.state.utmFilled}/>
                                <Form.Control.Feedback type="invalid">All UTM parameters must be either filled or empty</Form.Control.Feedback>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className='mb-3'>
                            <Col sm={3} />
                            <Col sm={9}>
                            <Button variant="primary" type="submit" disabled={!this.state.longUrl}>
                                Submit
                            </Button>
                            </Col>
                        </Form.Group>
                    </Form>
                </div>

                {
                    this.state.submitted
                    ? <div> <Alert variant='success'>{this.state.shortUrl}</Alert> </div>
                    : null
                }

            </div>
        );
    }
}

export default Shortener