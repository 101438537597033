import React from 'react';
import Shortener from './Shortener'
import SocialBar from './SocialBar';

import './styles/Common.css'

class App extends React.Component {

  render() {
    return (
      <div className='root-container'>
        <Shortener />
        <SocialBar />
      </div>
    );
  }
}

export default App;
