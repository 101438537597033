import React from 'react';

class SocialBar extends React.Component {

    render() {
        return(
            <div className='social-bar'>
                <a href='https://akvelon.global/'><img className='social-bar-img' src={require('./assets/site.png')} alt='Site' /></a>
                <a href='https://t.me/job_akvelon'><img className='social-bar-img' src={require('./assets/telegram.png')} alt='Telegram' /></a>
                <a href='https://www.instagram.com/akvelon.it/'><img className='social-bar-img' src={require('./assets/instagram.png')} alt='Instagram' /></a>
                <a href='https://www.facebook.com/Akvelon.IT'><img className='social-bar-img' src={require('./assets/facebook.png')} alt='Facebook' /></a>
            </div>
        );
    }
}

export default SocialBar